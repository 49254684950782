<template>
  <div class="fill-height">
    <PotensiMain
        jenis="PELUANG"
    ></PotensiMain>
  </div>
</template>

<script>
import PotensiMain from "../../components/public_potensi_kawasan/PotensiMain";

export default {
  name: "PeluangView",
  components:{
    PotensiMain
  }
}
</script>

<style scoped>

</style>